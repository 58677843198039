import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import { HelmetDatoCms } from "gatsby-source-datocms";

import PageBuilder from "../components/pagebuilder";

import { useI18next } from "gatsby-plugin-react-i18next";
import LogoFalling from "../sections-static/logo-falling";
import ChildLink from "../components/child-link";

const CategoryPage = ({ data }) => {
  const { language } = useI18next();
  return (
    <Layout>
      {data.page.nodes.map((page) => (
        <main key={page.id} className="page-builder">
          <HelmetDatoCms seo={page.seoMetaTags} />
          <div className="blog-page-title infront">
            <h1>{page.title}</h1>
            <div class="filters">
              <h6>{data.other.other}</h6>
              {data.otherCategories.nodes.map((category) => (
                <ChildLink
                  href={`/category/${category.slug}`}
                  classes="blog-tag"
                  language={language}
                  key={category.id}
                >
                  <span class="btn-text">{category.title}</span>
                </ChildLink>
              ))}
            </div>
          </div>
          <PageBuilder lang={language} data={page.builder} />
          <div className="blog-page-title other-final infront">
            <div class="filters">
              <h6>{data.other.other}</h6>
              {data.otherCategories.nodes.map((category) => (
                <ChildLink
                  href={`/category/${category.slug}`}
                  classes="blog-tag"
                  language={language}
                  key={category.id}
                >
                  <span class="btn-text">{category.title}</span>
                </ChildLink>
              ))}
            </div>
          </div>
          <LogoFalling />
        </main>
      ))}
    </Layout>
  );
};
export default CategoryPage;

export const query = graphql`
  query CategoryPageQuery($language: String!, $id: String!) {
    other: datoCmsCategoryGlobal(locale: { eq: $language }) {
      other
    }
    page: allDatoCmsCategory(
      filter: { originalId: { eq: $id }, locale: { eq: $language } }
    ) {
      nodes {
        originalId
        id
        title
        slug
        cta
        builder {
          ... on DatoCmsImageContent {
            ...ImageContent
          }
          ... on DatoCmsColumnedList {
            ...ColumnedList
          }
          ... on DatoCmsCarousel {
            ...Carousel
          }
          ... on DatoCmsFeatureCarousel {
            ...Features
          }
          ... on DatoCmsNicheSelector {
            ...Niches
          }
          ... on DatoCmsCustomerTestimonial {
            ...Testimonials
          }
          ... on DatoCmsValuesList {
            ...Values
          }
          ... on DatoCmsCallToActionBox {
            ...CTABox
          }
          ... on DatoCmsImageVideo {
            ...ImageVideo
          }
        }
        seoMetaTags {
          ...GatsbyDatoCmsSeoMetaTags
        }
      }
    }
    otherCategories: allDatoCmsCategory(
      filter: { originalId: { ne: $id }, locale: { eq: $language } }
    ) {
      nodes {
        originalId
        id
        title
        slug
        cta
        description
      }
    }
  }
`;
